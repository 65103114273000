@use '@angular/material' as mat;

@include mat.elevation-classes();
@include mat.app-background();

$primary-color: (
  50 : #ebe6f0,
  100 : #cdc0da,
  200 : #ac96c1,
  300 : #8b6ba8,
  400 : #724c95,
  500 : #592c82,
  600 : #51277a,
  700 : #48216f,
  800 : #3e1b65,
  900 : #2e1052,
  A100 : #ba8bff,
  A200 : #9c58ff,
  A400 : #7d25ff,
  A700 : #6e0bff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$accent-color: (
  50 : #e0e0e0,
  100 : #b3b3b3,
  200 : #808080,
  300 : #4d4d4d,
  400 : #262626,
  500 : #000000,
  600 : #000000,
  700 : #000000,
  800 : #000000,
  900 : #000000,
  A100 : #a6a6a6,
  A200 : #8c8c8c,
  A400 : #737373,
  A700 : #666666,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$primary: mat.m2-define-palette($primary-color);
$accent: mat.m2-define-palette($accent-color);
$warn: mat.m2-define-palette(mat.$m2-red-palette);
$theme: mat.m2-define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
    warn: $warn,
  )
));

@include mat.all-component-themes($theme);

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --top-bar-height: 62px;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Inter, sans-serif;
}

$topBarHeight: 62px;

$heroSectionHeight: calc(100dvh - #{$topBarHeight});
.hero-section-height {
  height: $heroSectionHeight;
}

.top-bar-height {
  height: $topBarHeight;
}

/* Material input outline input issue fix */
.mdc-notched-outline__notch {
  border-right: none;
}

mat-error {
  @apply text-caption py-1;
}

/* Scrollbar style */

* {
  &::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    border-radius: 10px;
    background-color: grey;
    background-clip: content-box;
    -webkit-background-clip: content-box;
    transition: background-color .8s;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: grey;
    background-clip: border-box;
    -webkit-background-clip: border-box;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 0px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    width: 16px;
  }
}

.nav-link {
  @apply py-4 text-body2 cursor-pointer font-medium whitespace-nowrap transition-all duration-200 border-b-2;
}

.nav-link-active {
  @apply text-primary-600 border-primary-600;
}

.nav-link-inactive {
  @apply border-transparent text-neutral-500 hover:border-neutral-300;
}

.mat-bottom-sheet-container {
  padding: 0 !important;
}

.cdk-overlay-container:focus,
.cdk-overlay-pane:focus,
.cdk-overlay-container *:focus {
  outline: none !important;
}
